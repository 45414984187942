import React, { useEffect, useRef } from 'react';
import Animation from './Animation';

const AnimationComponent = ({ containerId, canvasId, options }) => {
  const animationRef = useRef(null);

  useEffect(() => {
    animationRef.current = new Animation(containerId, canvasId, options);
    animationRef.current.init();

    return () => {
      if (animationRef.current) {
        window.removeEventListener('mousemove', animationRef.current.mouseMove);
        window.removeEventListener('scroll', animationRef.current.scrollCheck);
        window.removeEventListener('resize', animationRef.current.resize);
      }
    };
  }, [containerId, canvasId, options]);

  return (
    <div id={containerId} style={{ width: '100%', height: '100%' }}>
      <canvas id={canvasId}></canvas>
    </div>
  );
};

export default AnimationComponent;