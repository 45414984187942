import * as React from "react"
import pic1 from '../../assets/images/business_intelligence.png'
import pic2 from '../../assets/images/business.png'

export default function() {
    return <>
                <div id="datareporter" className="center-section data-reporter">
                    <div className="center-padding">
                      <div className="left-collum">
                        <h2>Explore and Discover Your Data</h2>
                        <span className="description">
                        Here's the deal: DataReporter turns your raw numbers into powerful stories. 
                        With it, anyone—yes, even the non-technical folks—can create stunning reports and dashboards. 
                        The key? Interactivity. You're not just looking at static charts; you're diving in, making your data speak louder. 
                        </span>
                        {/* <a className="more" href="#">Read more</a> */}
                      </div>
                      <div className="right-collum">
                        <img src={pic1} alt=""/>
                      </div>
                    </div>
                </div>
                <div className="clear"></div>
                <div className="center-section technology">
                    <div className="center-padding">
                      <div className="left-collum is-desktop">
                        <img src={pic2} alt=""/>
                      </div>
                      <div className="right-collum">
                        <h2>Technology That Works for You</h2>
                        <span className="description">
                        It's all about control.

                        You decide where your data lives. Whether it's on Google CloudRun — a serverless, cost-efficient Google cloud option — or within your very own Kubernetes cluster. 
                        DataReporter fits right into your existing tech ecosystem. 
                        
                        Infrastructure Freedom: Pay only for what you use. Your data, your control.
                        <br /><br />
                        Prefer open-source? Download DataReporter and host it yourself.
                        </span>
                        {/* <a className="more" href="#">Learn more</a> */}
                      </div>
                      <div className="left-collum is-mobile">
                        <img src={pic2} alt=""/>
                      </div>
                    </div>
                </div>
                <div className="clear"></div>
                <div className="center-section data-security">
                  <div className="center-padding">
                    <div className="left-collum">
                      <h2>
                        Solutions
                      </h2>
                      <span className="description">
                        Develop curiosity about your data. 
                        DataReporter focuses on interactive data exploration allowing users to create powerful
                        reports and dashboards with a drag and drop.
                        Uncover patterns in your data without a need for a deep technical knowledge.
                      </span>
                      <span className="description">
                          DataReporter serves both the highly non-technical as well as technical users. 
                          Design Data Cubes as a code. 
                          All reports can be accessed via API.
                      </span>
                    </div>
                  </div>
                  <div className="right-collum">
                    <ul className="list-info">
                      <li><a href="#">Big Data Analytics</a></li>
                      <li><a href="#">BigQuery</a></li>
                      <li><a href="#">Athena</a></li>
                      <li><a href="#">All Databases</a></li>
                    </ul>
                  </div>
                </div>
                <div className="clear"></div>
                <div className="feedback-section">
                  <div className="center-section">
                    <div className="center-padding">
                      <div className="request-box">
                        <article>
                          Pay only for the infrastructure you use with the Google Cloud. 
                          {/* One click install in the Google Cloud.  */}
                          Available also as a Kubernetes deployment.
                        </article>
                        <div className="control-box">
                          {/* <div className="left is-desktop">
                            <a href="#" className="button">One Click Install</a>
                          </div> */}
                          <div className="left text-info">
                            <span className="red">*</span> You control the infrastructure and data in your Google Cloud.
                          </div>
                          <div className="left is-mobile">
                            <a href="#" className="button">Request a Free Demo</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="resources" className="center-section data-security">
                  <div className="center-padding">
                    <div className="left-collum">
                      <h2>
                        Resources
                      </h2>
                      <span className="description">
                        Check our documentation on how to harness the power of data and explore, query, visualize, 
                        and share data from your data sources.
                      </span>
                      <span className="description">
                        Check out GitHub resources on how to start with Datareporter. 
                        While datareporter interface is optimised towards non-technical users, you might need some IT support to install and set it up.
                      </span>
                      <span className="description">
                        Get to know Datareporter's simple modelling configuration that allows to define the BI cubes in a simple yet powerful way.
                      </span>
                    </div>
                  </div>
                  <div className="right-collum">
                    <ul className="list-info">
                      <li>
                        <a href="https://github.com/dataminelab">GitHub</a>
                      </li>
                      <li>
                        <a href="#">Documentation</a>
                      </li>
                      <li>
                        <a href="#">Installation</a>
                      </li>
                    </ul>
                  </div>
                </div>
           </>;
}
