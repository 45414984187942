import * as React from "react"
import FooterMenu from "./FooterMenu"

export default function() {
    return <>
            <div id="contact" className="left-collum">
              <h2>
                Datareporter
              </h2>
              <span className="description">
                             We focus on building great experiences for your
                              employees while we take care of scaling, updating,
                              and managing infrastructure.
                            </span>
              <div className="sub-description">
                <h2>
                  Get in touch
                </h2>
                <div className="left-collum">
                  <h4>Datamine Lab SIA</h4>
                  <span className="description">
                                  Brivibas Iela 129-46
                                  Riga 1012
                                  Latvia
                                </span>
                </div>
                <div className="right-collum">
                </div>
                <div className="clear"></div>
                <div className="social-box">
                  <a href="#" className="github"></a>
                  <a href="#" className="fb"></a>
                  <a href="#" className="instagram"></a>
                  <a href="#" className="twitter"></a>
                </div>
              </div>
            </div>
            <div className="right-collum">
              <h3>Send us a message</h3>
              <form action="#">
                <input type="text" placeholder="From: example@email.com"/>
                <textarea name="" id="" cols="30" rows="10" placeholder="Message..."></textarea>
                <button type={"submit"} className="button">Submit</button>
              </form>
            </div>
            <div className="clear"></div>
            <FooterMenu />
           </>;
}
