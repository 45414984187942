import { gsap } from 'gsap';

export default class Animation {
    constructor(containerId, canvasId, options) {
        this.width = 0;
        this.height = 0;
        this.containerId = containerId;
        this.canvasId = canvasId;
        this.options = options || {};
        this.options.color = this.options.color || 'rgba(156,217,249,';
        this.scale = options.scale || 1.5;
        this.density = options.density || 2; // New density factor
        this.container = null;
        this.canvas = null;
        this.ctx = null;
        this.points = [];
        this.target = { x: 0, y: 0 };
        this.animateHeader = true;

        this.mouseMove = this.mouseMove.bind(this);
        this.scrollCheck = this.scrollCheck.bind(this);
        this.resize = this.resize.bind(this);
        this.animate = this.animate.bind(this);
    }

    init() {
        this.initHeader();
        this.initAnimation();
        this.addListeners();
    }

    initHeader() {
        this.container = document.getElementById(this.containerId);
        this.canvas = document.getElementById(this.canvasId);
        if (!this.container || !this.canvas) {
            console.error('Container or canvas element not found');
            return;
        }

        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.target = { x: this.width / 2, y: this.height / 2 };

        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.ctx = this.canvas.getContext('2d');

        this.points = [];
        for (let x = 0; x < this.width; x = x + this.width / (20 / this.scale * this.density)) {
            for (let y = 0; y < this.height; y = y + this.height / (20 / this.scale * this.density)) {
                const px = x + Math.random() * this.width / (20 / this.scale * this.density);
                const py = y + Math.random() * this.height / (20 / this.scale * this.density);
                const p = { x: px, originX: px, y: py, originY: py };
                this.points.push(p);
            }
        }

        for (let i = 0; i < this.points.length; i++) {
            const closest = [];
            const p1 = this.points[i];
            for (let j = 0; j < this.points.length; j++) {
                const p2 = this.points[j];
                if (!(p1 === p2)) {
                    let placed = false;
                    for (let k = 0; k < 5; k++) {
                        if (!placed) {
                            if (closest[k] === undefined) {
                                closest[k] = p2;
                                placed = true;
                            }
                        }
                    }

                    for (let k = 0; k < 5; k++) {
                        if (!placed) {
                            if (this.getDistance(p1, p2) < this.getDistance(p1, closest[k])) {
                                closest[k] = p2;
                                placed = true;
                            }
                        }
                    }
                }
            }
            p1.closest = closest;
        }

        for (let i in this.points) {
            const c = new Circle(this.points[i], 2 * this.scale + Math.random() * 2 * this.scale, this.options.color);
            this.points[i].circle = c;
        }
    }

    addListeners() {
        if (!('ontouchstart' in window)) {
            window.addEventListener('mousemove', this.mouseMove);
        }
        window.addEventListener('scroll', this.scrollCheck);
        window.addEventListener('resize', this.resize);
    }

    mouseMove(e) {
        let posx = 0;
        let posy = 0;
        if (e.pageX || e.pageY) {
            posx = e.pageX;
            posy = e.pageY;
        } else if (e.clientX || e.clientY) {
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        this.target.x = posx;
        this.target.y = posy;
    }

    scrollCheck() {
        this.animateHeader = (document.body.scrollTop <= this.height);
    }

    resize() {
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }

    initAnimation() {
        this.animate();
        for (let i in this.points) {
            this.shiftPoint(this.points[i]);
        }
    }

    animate() {
        if (this.animateHeader) {
            this.ctx.clearRect(0, 0, this.width, this.height);
            for (let i in this.points) {
                if (Math.abs(this.getDistance(this.target, this.points[i])) < 4000 * this.scale) {
                    this.points[i].active = 0.3;
                    this.points[i].circle.active = 0.6;
                } else if (Math.abs(this.getDistance(this.target, this.points[i])) < 20000 * this.scale) {
                    this.points[i].active = 0.1;
                    this.points[i].circle.active = 0.3;
                } else if (Math.abs(this.getDistance(this.target, this.points[i])) < 40000 * this.scale) {
                    this.points[i].active = 0.02;
                    this.points[i].circle.active = 0.1;
                } else {
                    this.points[i].active = 0;
                    this.points[i].circle.active = 0;
                }

                this.drawLines(this.points[i]);
                this.points[i].circle.draw(this.ctx);
            }
        }
        requestAnimationFrame(this.animate);
    }

    shiftPoint(p) {
        gsap.to(p, {
            duration: 1 + 1 * Math.random(),
            x: p.originX - 50 * this.scale + Math.random() * 100 * this.scale,
            y: p.originY - 50 * this.scale + Math.random() * 100 * this.scale,
            ease: "circ.easeInOut",
            onComplete: () => {
                this.shiftPoint(p);
            }
        });
    }

    drawLines(p) {
        if (!p.active) return;
        for (let i in p.closest) {
            this.ctx.beginPath();
            this.ctx.moveTo(p.x, p.y);
            this.ctx.lineTo(p.closest[i].x, p.closest[i].y);
            this.ctx.strokeStyle = this.options.color + p.active + ')';
            this.ctx.stroke();
        }
    }

    getDistance(p1, p2) {
        return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
    }
}

class Circle {
    constructor(pos, rad, color) {
        this.pos = pos || null;
        this.radius = rad || null;
        this.color = color || null;
        this.active = 0;
    }

    draw(ctx) {
        if (!this.active) return;
        ctx.beginPath();
        ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = this.color + this.active + ')';
        ctx.fill();
    }
}