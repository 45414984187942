import React from "react";
import notebookImage from '../../assets/images/notebook.png';
import AnimationComponent from './AnimationComponent';

export default function SliderContainer() {
    return (
        <div className="slider-container">
            <AnimationComponent 
                containerId="slider-animation-container" 
                canvasId="slider-animation-canvas"
                options={{ 
                    color: 'rgba(156,217,249,',
                    scale: 4,
                    density: 2,
                    maxPoints: 100 // Adjust this to control the maximum number of points
                }}
            />
            <div className="slider-content">
                <div className="image-left">
                    <h1>From Questions to Answers</h1>
                    <h6>
                        DataReporter is your personal guide into the world of data exploration and visualization. Imagine having the power to drag and drop your way through complex data, revealing stories, patterns, and insights—all with just a few clicks.
                    </h6>
                </div>
                <div className="image-right">
                    <img src={notebookImage} alt="Note book"/>
                </div>
            </div>
        </div>
    );
}