import React, {useEffect, useState, useRef} from "react"
import logo from '../../assets/images/logo.png'
import close from '../../assets/images/close-menu.png'
import menu from '../../assets/images/menu.png'

export default function() {
    const [mobileMenu, setMobileMenu] = useState(false);
    const [selected, setSelected] = useState(false);
  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  }
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef()

    useEffect(() => {
      const anchors = document.querySelectorAll('a[href*="#"]')

      for (let anchor of anchors) {
        anchor.addEventListener('click', function (e) {
          e.preventDefault()

          const blockID = anchor.getAttribute('href').substr(1)

          document.getElementById(blockID).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        })
      }
    const header = ref.current;
    window.onscroll = function () {
      scrollPage()
    };
// Get the offset position of the navbar
    var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function scrollPage() {
      if (window.pageYOffset > sticky) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }, [])
  
  return (<div className={(isSticky ? "isSticky" : "")}>
    <div className="center-section">
      <div className="center-padding">
        <nav className={'header-top'} ref={ref}>
          <a href="/"><img className="logo" src={logo} alt="DATA Reporter"/></a>
          <ul className="nav-desktop">
            <li>
              <a href="#datareporter" className={selected === 1 ? 'active' : ''} onClick={() => setSelected(1)}>
                Technology
              </a>
            </li>
            <li>
              <a href="#resources" className={selected === 2 ? 'active' : ''} onClick={() => setSelected(2)}>
                Resources
              </a>
            </li>
            {/* <li>
                      <a href="#demo">
                        Demo
                      </a>
                    </li> */}
            <li>
              <a href="#contact" className={selected === 3 ? 'active' : ''} onClick={() => setSelected(3)}>
                Contact
              </a>
            </li>
          </ul>
          <ul className="nav-mobile-menu is-mobile">
            <li>
              <img src={menu} onClick={toggleMenu} alt="Open menu"/>
            </li>
          </ul>
          <div className={'nav-mobile ' + (mobileMenu ? 'open' : '')}>
            <img className="logo" src={logo} alt="DATA Reporter"/>
            <img className="close-menu" src={close} alt="close" onClick={toggleMenu}/>
            <h4>Menu</h4>
            <ul>
              <li>
                <a href="#datareporter">
                  Technology
                </a>
              </li>
              <li>
                <a href="#resources">
                  Resources
                </a>
              </li>
              <li>
                <a href="#security">
                  Data Security
                </a>
              </li>
              {/* <li>
                        <a href="#damoe">
                          Demo
                        </a>
                      </li> */}
              <li>
                <a href="#contact">
                  Contact
                </a>
              </li>
            </ul>
            <a href="#" className="button">Demo</a>
          </div>
        </nav>
      </div>
    </div>
  </div>)
}
